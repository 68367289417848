<template>
    <div :class="['CEESAR-Button', icon == undefined? '' : 'CEESAR-Button-WithIcon']" v-on:click="OnClick">
        <div class="CEESAR-ButtonIcon" v-if="icon != undefined">
            <MaterialDesign-Icon :icon="icon" size="24"/>
        </div>
        <div class="CEESAR-ButtonLabel" v-if="label != undefined">
            {{fullLabel}}
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-Button",
    props:{
        label: {type: String, default: undefined},
        url: {type: String, default: undefined},
        icon: {type: String, default: undefined}
    },
    computed:{
        fullLabel(){
            let label = "";
            if(this.emoji != undefined)
            {
                label = String.fromCodePoint(this.emoji) + " ";
            }
            label += this.label;
            return label;
        }
    },
    methods:
    {
        OnClick(e)
        {
            if(this.url != undefined)
            {
                window.location = this.url;
            }
            else
            {
                this.$emit("click");
                e.stopPropagation();
            }
        }
    }
}
</script>

<style>
</style>